import React from "react"
import Hero from "../../components/hero"
import SiteMetaData from "../../components/sitemeta"
import teamStyles from "../../components/teams.module.scss"
import TeamData from "../../components/team-data.js"
import Layout from "../../components/layout-teams"

const SalsaTeam = ({data}) => (
  <Layout data={data}>
    <div style={({"backgroundColor":"black"})}>
      <SiteMetaData 
        title="Salsa Teams | RF Dance"
        description="OC's Homegrown latin dance classes with Rhythmic Fusion. $15 for a single class, or $65 for a 5 class bundle." 
        pathname="teams/salsa"/>
      <Hero 
        titleRed="Salsa"
        titleWhite="Team" />

      <div className={`${teamStyles.auditions} content-block`}>
        <div className={`${teamStyles.container} container`}>
          <TeamData
              name="Beginner &amp; Beginner-Intermediate Auditions"
              subheading="No experience necessary"
              // ctaHeader="Stay tuned for the next auditions for this team."
              link="https://www.eventbrite.com/e/91729841425"
              // makeup="makeup"
              date="March 18, 2020"
              link2="https://www.eventbrite.com/e/91730042025"
              date2="March 19, 2020"
              >
              <p>RF’s Beginner/Beginner-Intermediate Salsa Team is a combined team geared towards those who are true beginners to Salsa and those who have been social dancing for less than two years. This team focuses on fine-tuning the basics as well as more simplified choreography to give a solid foundation for Salsa.</p>
            </TeamData>

          <TeamData
            name="Intermediate &amp; Intermediate-Advanced Auditions"
            subheading="2+ years experience"
            // ctaHeader="Stay tuned for the next auditions for this team."
            link="https://www.eventbrite.com/e/91730435201"
            // makeup="makeup"
            date="March 25, 2020"
            link2="https://www.eventbrite.com/e/92295543455"
            date2="March 26, 2020"
            >
            <p>The Intermediate/Intermediate-Advanced Salsa Team focuses on more advanced technical training as well as complex choreography for the stage. This team is perfect for those with 2+ years of Salsa dancing experience who feel they are ready for the challenge of taking their Salsa skills up a notch.</p>
          </TeamData>
        </div>
      </div>
    </div>
  </Layout>
)

export default SalsaTeam